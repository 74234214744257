
export const echartColors = {
    "yellow": "#E3E811",
    "green": "#74D70D",
    "black": "#000000",
    "brown": "#FFCF97",
    "blue": "#69FFFF",
    "purple": "#E6B2FF",
    "silverLine": "#E8E8E8",
    "greyLine": "rgba(232, 232, 232, 0.19)",
    "greyLabel": "#9B9B9B",
    "darkGreyLabel": "#646464",
    "white": "#ffffff",
}

const isDarkMode = document.documentElement.classList.contains('dark');

export const echartColorsProducer = isDarkMode ? [
    echartColors.yellow,
    echartColors.green,
    echartColors.white,
    echartColors.brown,
    echartColors.blue,
    echartColors.purple,
] : [
    echartColors.yellow,
    echartColors.green,
    echartColors.black,
    echartColors.brown,
    echartColors.blue,
    echartColors.purple,
];