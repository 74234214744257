import * as React from "react";
import Auth from "@/assets/rigle-auth.png";

export function AuthLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="flex items-start justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${Auth})` }}>
            <div className={"bg-[#1A1E16] rounded-b-[80px] p-10 shadow-md text-center w-[40rem] h-fit"}>
                {children}
            </div>
        </div>
    );
}
