import {useQueryClient} from "@tanstack/react-query";
import {useMemo} from "react";
import {createRouter} from "@/app/routes";
import {RouterProvider} from "react-router-dom";
import {AppProvider, useBrand} from "@/app/main-provider";

const AppRouter = () => {
    const queryClient = useQueryClient();
    const { brand, setBrand } = useBrand();

    const router = useMemo(() => createRouter(queryClient, brand, setBrand), [queryClient, brand, setBrand]);

    return <RouterProvider router={router} />
}

function App() {
    return (
        <AppProvider>
            <AppRouter />
        </AppProvider>
    );
}

export default App;