import * as React from "react";
import Button, {ButtonProps} from "@/components/ui/button/button";

type NewTabButtonProps = React.HTMLAttributes<HTMLButtonElement> & ButtonProps & {
    url: string;
}

export const NewTabButton = React.forwardRef<HTMLButtonElement, NewTabButtonProps>(
    (
        {
            url,
            ...props
        },
        ref
    ) => {
        const openNewTab = () => {
            window.open(url, "_blank");
        }

        return (
            <Button
                ref={ref}
                onClick={openNewTab}
                {...props}
            />
        );
    }
);