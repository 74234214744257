import {useProductCharts} from "@/features/products/api/get-component-chart";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import React from "react";
import {MultipleAxisTimeValueLineChart} from "@/components/ui/chart/line/multiple-axis-line";

export const ComponentChart = ({
    component_item,
    filter,
    dateRange,
}: {
    component_item: string;
    filter: string;
    dateRange: Range[];
}) => {
    const { brand, setBrand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const componentChartQuery = useProductCharts({
        brand: brand,
        period: filter,
        component_item: component_item,
        ...(filter.toLowerCase() === "custom" && {
            startDate: startDate?.toISOString().split("T")[0],
            endDate: endDate?.toISOString().split("T")[0],
        })
    });

    if (componentChartQuery.isLoading) {
        return <div>Component Chart Loading...</div>;
    }

    if (!componentChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueLineChart
            title={"Component Chart"}
            labels={[
                { name: "sales", position: "left" },
                { name: "orders", position: "right" },
            ]}
            series={[
                componentChartQuery.data.map(row => [row.date, row.sales]),
                componentChartQuery.data.map(row => [row.date, row.units])
            ]}
            height={"27.5rem"}
        />
    );
}