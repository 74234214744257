import { configureAuth } from 'react-query-auth';
import * as React from "react";
import {api} from "@/lib/api-client";
import {z} from "zod";
import {Navigate, useLocation} from "react-router-dom";
import {Token, UserInfoResponse} from "@/types";
import Cookies from "js-cookie";

export const getToken = () => {
    const token = Cookies.get("token") ?? sessionStorage.getItem("token");

    if (!token) return null;

    return token;
}

const getUser = (): Promise<UserInfoResponse> | null => {
    const token = getToken();

    if (!token) {
        return null;
    }
    return api.get('/auth/me');
}

const logout = (): Promise<void> => {
    Cookies.remove("token");
    return api.get('/auth/logout');
}

export const loginInputSchema = z.object({
    username: z.string().min(1, "Required").email("Invalid email"),
    password: z.string().min(6, "Required"),
});

export type LoginInput = z.infer<typeof loginInputSchema>;
export type LoginInputWithRememberPassword = LoginInput & {rememberPassword: boolean};
const loginWithEmailAndPassword = (data: LoginInputWithRememberPassword): Promise<Token> => {
    return api.post('/token', data);
}

export const registerInputSchema = z
    .object({
        firstName: z.string().min(1, "Required").max(50, "Input was too long"),
        lastName: z.string().min(1, "Required").max(50, "Input was too long"),
        email: z.string().min(1, "Required").email("Invalid email"),
        password: z.string()
            .min(8, "Password must be at least 8 characters long")
            .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
            .regex(/[a-z]/, "Password must contain at least one lowercase letter")
            .regex(/[0-9]/, "Password must contain at least one number")
            .regex(/[\W_]/, "Password must contain at least one special character"),
        confirmPassword: z.string().min(1, "Required"),
    })
    .refine((data) =>
        data.password === data.confirmPassword,
        {
            path: ["confirmPassword"],
            message: "Password doesn't match",
        }
    );

type RegisterInput = z.infer<typeof registerInputSchema>;

export type RegisterInputWithInvitationCode = RegisterInput & {invitationCode: string};

const registerWithInvitationLink = (
    data: RegisterInputWithInvitationCode,
): Promise<any> => {
    return api.post('/auth/register', data);
}

const authConfig = {
    userFn: getUser,
    loginFn: async (data: LoginInputWithRememberPassword): Promise<UserInfoResponse | null> => {
        const response = await loginWithEmailAndPassword(data);
        if (data.rememberPassword) {
            Cookies.set("token", response.access_token);
        } else {
            sessionStorage.setItem("token", response.access_token);
        }
        return getUser();
    },
    registerFn: async (data: RegisterInputWithInvitationCode) => {
        const response = await registerWithInvitationLink(data);
        return response.data;
    },
    logoutFn: logout,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } = configureAuth(authConfig);

export const ProtectedRoute = ({ children }: { children: React.ReactNode }): any => {
    const user = useUser();
    const location = useLocation();

    if (user.isLoading) {
        return <div>Protected Route Loading...</div>
    }

    if (!user.data){
        return (
            <Navigate
                to={`/auth/login?redirectTo=${encodeURIComponent(location.pathname)}`}
                replace
            />
        )
    }

    if (!localStorage.getItem("brand")) {
        localStorage.setItem("brand", user.data.brand[0])
    }
    localStorage.setItem("username", user.data.user_name);
    localStorage.setItem("email", user.data.user_email);
    return children;
}