import {useBrand} from "@/app/main-provider";
import {usePurchaseShortTerms} from "@/features/purchase/api/get-purchase-short-terms";
import PaginationTable, {ColumnVisMapperProps, ColumnVisMeta} from "@/components/ui/table/pagination-table/pagination-table";
import {Spinner} from "@/components/ui/spinner/spinner";

export const PurchaseShortTerms = ({
    filter,
}: {
    filter: string;
}) => {
    const { brand, setBrand } = useBrand();

    const purchaseShortTerms = usePurchaseShortTerms({
        brand: brand,
        selector: filter
    });

    if (purchaseShortTerms.isLoading) {
        return (
            <div className="flex h-48 w-full items-center justify-center">
                <Spinner size="lg"/>
            </div>
        );
    }

    if (!purchaseShortTerms.data) return null;

    return (
        <div>
            <PaginationTable
                data={purchaseShortTerms.data.data}
                columns={purchaseShortTerms.data.columns}
                initialFilters={{}}
                visMapper={{}}
            />
        </div>
    );
}