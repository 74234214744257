import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";
import {Card, CardHeader, CardBody} from "../card";
import {CardResponse} from "@/types";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import SimpleDropdown from "@/components/ui/dropdown/simple-dropdown/simple-dropdown";
import {Range} from "react-date-range";

const simpleCardVariants = cva(
    "flex flex-col gap-y-10",
    {
        variants: {
            variant: {
                default: "dark",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type CardProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof simpleCardVariants> & {
    data: CardResponse;
    filter: string;
    setFilter: (filter: string) => void;
    dateRange?: Range[];
    setDateRange?: (dateRange: Range[]) => void;
};


const SimpleCard = React.forwardRef<HTMLDivElement, CardProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            filter,
            setFilter,
            dateRange,
            setDateRange,
            ...props
        },
        ref,
    ) => {
        return (
            <div className={cn(simpleCardVariants({variant, size, className}))}>
                <div className={"flex flex-row gap-x-5 justify-between"}>
                    <span className={"font-bold text-3xl"}>{children}</span>
                    <SimpleDropdown
                        filter={filter}
                        filterOptions={[]}
                        setFilter={setFilter}
                        className={"w-32"}
                    />
                    {
                        filter.toLowerCase() === "custom" ? dateRange && setDateRange &&
                            <DateRangeDropdown dateRange={dateRange}
                                               setDateRange={setDateRange} /> : null
                    }
                </div>

                <div className={"flex gap-x-16"}>
                    {data.summary.map((summary, index) => (
                        <Card key={index}>
                            <CardHeader>{summary.subtitle}</CardHeader>
                            {summary.data.map((item, index) => (
                                <CardBody key={index}>
                                    <span className={"font-semibold text-gray-500 text-base mr-2"}>{item.name}</span>
                                    {
                                        (["Total Sales", "Sales", "Ad Sales"].includes(item.name || summary.subtitle) ? '$' : '') +
                                            item.value
                                    }
                                </CardBody>
                            ))}
                        </Card>
                    ))}

                </div>
            </div>
        )
    },
);

export default SimpleCard;