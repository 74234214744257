import {ProductsListResponse} from "@/types";
import {api} from "@/lib/api-client";
import {queryOptions, useQuery} from "@tanstack/react-query";
import {QueryConfig} from "@/lib/react-query";

export const getSalesItems = ({
                                     brand,
                                     period,
                                     startDate,
                                     endDate,
                                 }: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<ProductsListResponse> => {
    return api.get(`/sales/items`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getSalesItemsQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["sales", "items", brand, period, startDate, endDate],
        queryFn: () => getSalesItems({ brand, period, startDate, endDate }),
    })
}

type UseSalesItemsOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getSalesItemsQueryOptions>;
}

export const UseSalesItems = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig
}: UseSalesItemsOptions) => {
    return useQuery({
        ...getSalesItemsQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}