import * as React from "react";
import {useRef, useState} from "react";

import {cva, VariantProps} from "class-variance-authority";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FilterMatchMode, FilterService} from "primereact/api";

import {AdListData, Columns, ProductDetailLinkData, ProductsListData} from "@/types";
import {cn} from "@/utils/cn";
import {Toolbar} from "primereact/toolbar";
import Button from "@/components/ui/button/button";
import {NewTabButton} from "@/components/ui/button/newtab-button/newtab-button";
import {env} from "@/config/env";
import {useBrand} from "@/app/main-provider";
import {useLocation} from "react-router-dom";
import {
    DataTableNumberFilterBox,
    DataTableRangeFilterBox,
    DataTableSearchBar,
    DataTableSortAscendingIcon,
    DataTableSortDescendingIcon,
    DataTableSortIcon
} from "@/components/ui/table/datatable";
import {Tooltip} from "primereact/tooltip";
import {Range} from "react-date-range";
import ViewDetailIcon from "@/assets/icons/button/detail-icon.svg";
import AmazonIcon from "@/assets/icons/button/amazon-icon.svg";
import {TableNoData} from "@/components/errors/table-no-data";

const paginationTableVariants = cva(
    "rounded-xl bg-white dark:bg-rigleDark",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type FilterProps = {
    value: any;
    matchMode: FilterMatchMode;
};

export type CustomFilterMeta = {
    [key: string]: FilterProps;
}

export enum ColumnVisMeta {
    Image,
    Amz_link,
    Detail_link,
    Merge_toImage,
    Long_text,
}

export type ColumnVisMapperProps = {
    [key: string]: ColumnVisMeta;
}

type DataSchema = ProductsListData | AdListData | ProductDetailLinkData | object;

export type ProductTableProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof paginationTableVariants> & {
    data: DataSchema[];
    columns: Columns[];
    initialFilters: CustomFilterMeta;
    visMapper: ColumnVisMapperProps;
    dateRange?: Range[];
};

const PaginationTable = React.forwardRef<HTMLDivElement, ProductTableProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            columns,
            initialFilters,
            visMapper,
            dateRange,
            ...props
        },
        ref
    ) => {
        Object.entries(initialFilters).forEach(([key, value]) => {
            if (value.matchMode == FilterMatchMode.CUSTOM) {
                FilterService.register(`custom_${key}`, (value, filters) => filterByRange(value, filters));
            }
        })
        initialFilters = {
            ...initialFilters,
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }

        const location = useLocation();
        const [filters, setFilters] = useState<CustomFilterMeta>(initialFilters);
        const dt = useRef<DataTable<DataSchema[]>>(null);
        const {brand, setBrand} = useBrand();

        if (data.length === 0) {
            return <TableNoData />
        }

        const fieldColNameMapper = Object.keys(data[0]).reduce((mapper, val, index) => {
            mapper[val] = columns[index].name;
            mapper[columns[index].name] = val;
            return mapper;
        }, {} as { [key: string]: string });

        const colVisMapper = Object.entries(visMapper).reduce((mapper, [key, value]) => {
            mapper[key] = value;
            mapper[value] = key;
            return mapper;
        }, {} as { [key: string]: string | ColumnVisMeta });

        const exportCSV = () => {
            dt.current?.exportCSV();
        };

        return (
            <div className={"datatable-filter"}>
                <div ref={ref} className={cn(paginationTableVariants({variant, size, className}))} {...props} >
                    {
                        (Object.keys(filters).length === 1 && "global" in filters) ?
                            null
                            :
                            (
                                <Toolbar
                                    className="rounded-t-[14px] rounded-b-none border-t-0 border-x-0 border-[#EBEBEB] bg-white dark:bg-rigleDark dark:border-rigleGreyLine"
                                    start={() => exportToolbarTemplate({filters, setFilters, fieldColNameMapper})}
                                    end={() => resetFilterButton({initialFilters, setFilters})}
                                />
                            )
                    }

                    <DataTable
                        ref={dt}
                        className={""}
                        value={data}
                        paginator
                        paginatorTemplate={"CurrentPageReport JumpToPageInput FirstPageLink PrevPageLink NextPageLink LastPageLink"}
                        currentPageReportTemplate={"{currentPage} of {totalPages}"}
                        rows={10}
                        header={() => DataTableHeader({filters, setFilters, exportCSV, dateRange})}
                        tableStyle={{minWidth: '60rem'}}
                        filters={filters}
                        globalFilterFields={Object.keys(data[0]).filter(k => k !== "hyperlink")}
                        emptyMessage={"No Products Found"}
                        exportFilename={location.pathname.replace("/app/", "")}
                        sortIcon={customSortIcon}
                    >
                        {Object.entries(data[0]).map(([k, v], index) => {
                            if (colVisMapper[k] === ColumnVisMeta.Image) {
                                return (
                                    <Column
                                        header={fieldColNameMapper[k]}
                                        field={k}
                                        sortable
                                        body={(product: DataSchema) => imageBodyTemplate({product, colVisMapper})}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Merge_toImage) {
                                return null
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Amz_link) {
                                return (
                                    <Column
                                        field={k}
                                        body={(product: any) => (
                                            <NewTabButton
                                                url={product[k]}
                                                icon={<img src={AmazonIcon} alt={"Amazon Link Icon"} />}
                                                variant="amazonLink"
                                                size={"tableLink"}
                                            >
                                                Amazon
                                            </NewTabButton>
                                        )}
                                        header={fieldColNameMapper[k]}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Detail_link) {
                                return (
                                    <Column
                                        field={k}
                                        body={(product: any) => (
                                            <NewTabButton
                                                url={`${env.APP_URL}app/products/stats/${product[k]}?brand=${brand}`}
                                                icon={<img src={ViewDetailIcon} alt={"View Detail Icon"}/>}
                                                variant={"detailLink"}
                                                size={"tableLink"}
                                            >
                                                View Details
                                            </NewTabButton>
                                        )}
                                        header={fieldColNameMapper[k]}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Long_text) {
                                return (
                                    <Column
                                        header={fieldColNameMapper[k]}
                                        field={k}
                                        sortable
                                        body={(product: any, options) => {
                                            return (
                                                <>
                                                    <span
                                                        id={`cell-${options.rowIndex}-${index}`}
                                                        className={"truncate"}
                                                        data-pr-position="bottom"
                                                    >
                                                        {product[k].length > 20 ? `${product[k].substring(0, 20)}...` : product[k]}
                                                    </span>
                                                    <Tooltip
                                                        target={`#cell-${options.rowIndex}-${index}`}
                                                        content={product[k]}
                                                    />
                                                </>
                                            );
                                        }}
                                    />
                                );
                            }

                            return (
                                <Column
                                    header={fieldColNameMapper[k]}
                                    field={k}
                                    sortable
                                />
                            );
                        })}
                    </DataTable>
                </div>
            </div>
        )
    }
);

const filterByRange = (value: number, filters: any) => {
    // @ts-ignore
    const [from, to] = filters ?? [null, null];
    if (from === null && to === null) return true;
    if (from !== null && to === null) return from <= value;
    if (from === null && to !== null) return value <= to;
    return from <= value && value <= to;
}

const resetFilters = ({
  initialFilters,
  setFilters,
}: {
    initialFilters: CustomFilterMeta;
    setFilters: (filters: CustomFilterMeta) => void;
}) => {
    setFilters(initialFilters);
}

const resetFilterButton = ({
    initialFilters,
    setFilters,
}: {
    initialFilters: CustomFilterMeta;
    setFilters: (filters: CustomFilterMeta) => void;
}) => (
    <Button className={"ml-auto col-start-6 flex justify-center"}
            onClick={() => resetFilters({initialFilters, setFilters})}>
        <div className={"dark:text-rigleDarkGrey"}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.9735 6.99125C12.8266 6.27147 12.5397 5.5878 12.129 4.97937C11.7255 4.3808 11.2115 3.86526 10.6146 3.46063C10.0078 3.04895 9.32614 2.76118 8.60846 2.61375C8.23148 2.53708 7.84765 2.49938 7.46299 2.50125V1.25L4.98571 3.125L7.46299 5V3.75125C7.76463 3.75 8.06627 3.77875 8.35731 3.83875C8.9151 3.95338 9.44493 4.17701 9.91659 4.49688C10.3817 4.8114 10.7821 5.21289 11.0957 5.67937C11.5827 6.40139 11.8423 7.25335 11.8411 8.125C11.841 8.70993 11.7244 9.28895 11.4983 9.82812C11.3883 10.0878 11.254 10.3365 11.097 10.5706C10.9394 10.8034 10.7603 11.0208 10.5622 11.22C9.95897 11.8239 9.19311 12.2381 8.35855 12.4119C7.77817 12.5294 7.18022 12.5294 6.59984 12.4119C6.04178 12.2971 5.51173 12.0733 5.03993 11.7531C4.57531 11.4389 4.1754 11.0378 3.86205 10.5719C3.37567 9.84909 3.11588 8.99698 3.11606 8.125H1.86963C1.87029 9.24606 2.20422 10.3415 2.82876 11.2712C3.23251 11.8688 3.74605 12.3838 4.34193 12.7887C5.26779 13.4172 6.36059 13.7521 7.47857 13.75C7.85828 13.75 8.23702 13.7116 8.60909 13.6356C9.32624 13.4871 10.0075 13.1994 10.6146 12.7887C10.9127 12.5873 11.1905 12.3573 11.4441 12.1019C11.6981 11.8465 11.9276 11.5678 12.1296 11.2694C12.7557 10.3411 13.0894 9.24568 13.0875 8.125C13.0875 7.74421 13.0493 7.36438 12.9735 6.99125Z"
                    fill="currentColor"/>
            </svg>
        </div>
        <span className={"text-[#696969] dark:text-rigleDarkGrey translate-y-0.5 ml-1.5 font-bold"}>Reset filter</span>
    </Button>
)

const exportToolbarTemplate = ({
    filters,
    setFilters,
    fieldColNameMapper,
}: {
    filters: CustomFilterMeta;
    setFilters: (filters: (prevFilter: CustomFilterMeta) => CustomFilterMeta) => void;
    fieldColNameMapper: { [key: string]: string };
}) => {
    return (
        <div className={"grid grid-cols-6 gap-4"}>
            {Object.entries(filters).map(([key, filter], index) => {
                if (key === "global") return null;

                if (filter.matchMode === FilterMatchMode.CUSTOM) {
                    return (
                        <DataTableRangeFilterBox
                            filters={filters}
                            setFilters={setFilters}
                            filterKey={key}
                            filter={filter}
                            columnName={fieldColNameMapper[key]}
                        />
                    );
                }

                return (
                    <DataTableNumberFilterBox
                        filters={filters}
                        setFilters={setFilters}
                        filterKey={key}
                        filter={filter}
                        columnName={fieldColNameMapper[key]}
                    />
                );
            })}

        </div>
    );
};

const DataTableHeader = ({
    filters,
    setFilters,
    exportCSV,
    dateRange,
}: {
    filters: CustomFilterMeta;
    setFilters: (filters: (prevFilter: CustomFilterMeta) => CustomFilterMeta) => void;
    exportCSV: () => void;
    dateRange: Range[] | undefined;
}) => {
    let dateRangeText: string = "";
    if (dateRange) {
        const { startDate, endDate } = dateRange[0];
        dateRangeText = `${startDate?.toISOString().split("T")[0]} - ${endDate?.toISOString().split("T")[0]}`;
    }

    return (
        <div className={"flex"}>
            <DataTableSearchBar filters={filters} setFilters={setFilters}/>

            <span className={"flex items-center dark:text-white mr-3"}>{dateRangeText}</span>
            <Button variant={"tableExport"} onClick={exportCSV}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.25 7.5L9 11.25L12.75 7.5" stroke="black" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9 11.25V2.25" stroke="black" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
                <span className={"ml-1 font-bold"}>Export</span>
            </Button>
        </div>
    );
};

const imageBodyTemplate = ({
    product,
    colVisMapper,
}: {
    product: any,
    colVisMapper: { [key: string]: string | ColumnVisMeta }
}) => {
    const imgData = colVisMapper[ColumnVisMeta.Image]
    const skuData = colVisMapper[ColumnVisMeta.Merge_toImage]

    return (
        <div className={"gap-x-4 text-center"}>
            <div className={"bg-white mx-2 flex justify-center"}>
                <img src={`${product[imgData]}`} alt={`${product[imgData]}`}
                     className="w-[6rem] h-[4.1rem] object-scale-down shadow-2 border-round"/>
            </div>
            <span>{product[skuData]}</span>
        </div>
    );
};

const customSortIcon = (options: any) => {
    if (options.field === options.sortField) {
        if (options.sortOrder > 0) return <DataTableSortAscendingIcon/>;
        else if (options.sortOrder < 0) return <DataTableSortDescendingIcon/>;
    }

    return <DataTableSortIcon/>;
}

export default PaginationTable;