import {QueryClient} from "@tanstack/react-query";
import React, {useState} from "react";
import {getAdvertisingQueryOptions} from "@/features/advertising/api/get-advertising";
import {getAdvertisingChartQueryOptions} from "@/features/advertising/api/get-advertising-chart";
import {getAdvertisingItemsQueryOptions} from "@/features/advertising/api/get-advertising-items";
import {useBrand} from "@/app/main-provider";
import {Range} from "react-date-range";
import {AdvertisingCard} from "@/features/advertising/components/advertising-card";
import {AdvertisingChart} from "@/features/advertising/components/advertising-chart";
import {AdvertisingItems} from "@/features/advertising/components/advertising-items";
import {date} from "zod";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {AdvertisingPie} from "@/features/advertising/components/advertising-pie";
import {AdvertisingFunnel} from "@/features/advertising/components/advertising-funnel";

export const advertisingDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;
    // const advertisingQuery = getAdvertisingQueryOptions({
    //     brand: brand,
    // });

    const advertisingChartQuery = getAdvertisingChartQueryOptions({
        brand: brand,
    });

    const advertisingItemsQuery = getAdvertisingItemsQueryOptions({
        brand: brand,
    });

    const promises = [
        queryClient.getQueryData(advertisingChartQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingChartQuery)),
        queryClient.getQueryData(advertisingItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingItemsQuery)),
    ] as const;

    const [ advertisingChart, advertisingItems ] = await Promise.all(promises);

    return {
        advertisingChart,
        advertisingItems,
    }
}

export const AdvertisingDashboardRoute = () => {
    const { brand, setBrand } = useBrand();
    const [filter, setFilter] = useState("Daily");
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
            endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            {/*<AdvertisingCard*/}
            {/*    filter={filter}*/}
            {/*    setFilter={setFilter}*/}
            {/*    dateRange={dateRange}*/}
            {/*    setDateRange={setDateRange}*/}
            {/*/>*/}
            <div className={"flex justify-between"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Advertisement</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <div className={"grid grid-cols-6 gap-x-5"}>
                <div className={"col-span-2"}>
                    <AdvertisingPie
                        filter={filter}
                        dateRange={dateRange}
                    />
                </div>
                <div className={"col-span-4"}>
                    <AdvertisingFunnel
                        filter={filter}
                        dateRange={dateRange}
                    />
                </div>
            </div>
            <AdvertisingChart
                filter={filter}
                dateRange={dateRange}
            />
            <AdvertisingItems
                filter={filter}
                dateRange={dateRange}
            />
        </>
    );
}