import {queryOptions, useQuery} from "@tanstack/react-query";

import {SalesChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getSalesAnnualChart = ({
    brand,
}: {
    brand: string;
}): Promise<SalesChartResponse[]> => {
    return api.get(`/sales/annual-chart`, {
        params: {
            brand,
        },
    });
}

export const getSalesAnnualChartQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["sales", "chart", brand],
        queryFn: () => getSalesAnnualChart({ brand }),
    });
};

type UseSalesChartOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getSalesAnnualChartQueryOptions>;
}

export const useSalesAnnualChart = ({
    brand,
    queryConfig
}: UseSalesChartOptions) => {
    return useQuery({
        ...getSalesAnnualChartQueryOptions({ brand }),
        ...queryConfig,
    })
}