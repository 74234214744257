import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdFunnelResponse, AdOrganicPieResponse} from "@/types";

export const getAdvertisingFunnel = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdFunnelResponse[]> => {
    return api.get(`/advertising/funnel`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getAdvertisingFunnnelQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "funnel", brand, period, startDate, endDate],
        queryFn: () => getAdvertisingFunnel({ brand, period, startDate, endDate }),
    })
}

type UseAdvertisingFunnelOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAdvertisingFunnnelQueryOptions>;
}

export const useAdvertisingFunnel = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAdvertisingFunnelOptions) => {
    return useQuery({
        ...getAdvertisingFunnnelQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}