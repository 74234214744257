import {queryOptions, useQuery} from "@tanstack/react-query";

import {PurchaseChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getPurchaseChart = ({
    brand,
}: {
    brand: string;
}): Promise<PurchaseChartResponse> => {
    return api.get(`/purchase/chart`, {
        params: {
            brand,
        },
    });
}

export const getPurchaseChartQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["purchase", "chart", brand],
        queryFn: () => getPurchaseChart({ brand }),
    })
}

type UsePurchaseChartOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getPurchaseChartQueryOptions>;
}

export const usePurchaseChart = ({
    brand,
    queryConfig
}: UsePurchaseChartOptions) => {
    return useQuery({
        ...getPurchaseChartQueryOptions({ brand }),
        ...queryConfig
    })
}