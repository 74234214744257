import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {useAdvertisingPie} from "@/features/advertising/api/get-advertising-pie";
import {DonutChart} from "@/components/ui/chart/pie/pie";

export const AdvertisingPie = ({
    filter,
    dateRange,
    height = "18.5rem",
}: {
    filter: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand, setBrand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingPieQuery = useAdvertisingPie({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (advertisingPieQuery.isLoading) {
        return <div>Advertising Pie Loading...</div>;
    }

    if (!advertisingPieQuery.data) return null;

    return (
        <DonutChart
            title={"Organic vs Inorganic"}
            labels={["Organic", "In-Organic"]}
            series={advertisingPieQuery.data.map(row => [row.name, row.value])}
            height={height}
        />
    );
}