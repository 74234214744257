import {queryOptions, useQuery} from "@tanstack/react-query";

import {PurchaseListResponse} from "@/types";
import {api} from "@/lib/api-client";
import {queryConfig, QueryConfig} from "@/lib/react-query";

export const getPurchaseShortTerms = ({
    brand,
    selector,
}: {
    brand: string;
    selector: string;
}): Promise<PurchaseListResponse> => {
    return api.get(`/purchase/short-terms`, {
        params: {
            brand,
            selector,
        },
    });
}

export const getPurchaseShortTermsQueryOptions = ({
    brand,
    selector,
}: {
    brand: string;
    selector: string;
}) => {
    return queryOptions({
        queryKey: ["purchase", "short-terms", brand, selector],
        queryFn: () => getPurchaseShortTerms({ brand, selector }),
    })
}

type UsePurchaseShortTermsOptions = {
    brand: string;
    selector: string;
    queryConfig?: QueryConfig<typeof getPurchaseShortTermsQueryOptions>;
}

export const usePurchaseShortTerms = ({
    brand,
    selector,
    queryConfig,
}: UsePurchaseShortTermsOptions) => {
    return useQuery({
        ...getPurchaseShortTermsQueryOptions({ brand, selector }),
        ...queryConfig
    })
}