import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {CardResponse} from "@/types";

export const getPurchase = ({
    brand,
}: {
    brand: string;
}): Promise<CardResponse> => {
    return api.get(`/purchase`, {
        params: {
            brand,
        },
    });
}

export const getPurchaseQueryOptions = ({
    brand,
}: {
    brand: string;
}) => {
    return queryOptions({
        queryKey: ["purchase", brand],
        queryFn: () => getPurchase({ brand }),
    })
}

type UsePurchaseQueryOptions = {
    brand: string;
    queryConfig?: QueryConfig<typeof getPurchaseQueryOptions>;
}

export const usePurchase = ({
    brand,
    queryConfig,
}: UsePurchaseQueryOptions) => {
    return useQuery({
        ...getPurchaseQueryOptions({ brand }),
        ...queryConfig
    })
}