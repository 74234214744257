import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {
    DropdownIconDown,
    DropdownIconUp,
    DropdownMenu,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {useEffect, useRef, useState} from "react";
import SimpleDateRangePicker from "@/components/ui/date-range/date-range";
import {Range} from "react-date-range";

const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type DateRangeDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
}

const DateRangeDropdown = React.forwardRef<HTMLDivElement, DateRangeDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            dateRange,
            setDateRange,
            ...props
        },
        ref
    ) => {
        const [tmpDateRange, setTmpDateRange] = React.useState<Range[]>(dateRange);
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const toggleDropdown = () => {
            setIsOpen((prevIsOpen) => {
                if (prevIsOpen) {
                    setTmpDateRange((currentTmpDateRange) => {
                        setDateRange(currentTmpDateRange);
                        return currentTmpDateRange;
                    });
                }
                return !prevIsOpen;
            });
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setTmpDateRange((currentTmpDateRange) => {
                    setDateRange(currentTmpDateRange);
                    return currentTmpDateRange;
                });
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef}>
                <DropdownTrigger aria-expanded={isOpen} onClick={toggleDropdown}>
                    Choose Date
                    {
                        isOpen ? <DropdownIconUp /> : <DropdownIconDown />
                    }
                </DropdownTrigger>

                {
                    isOpen ? (
                        <SimpleDateRangePicker
                            className={`absolute z-20`}
                            dateRange={tmpDateRange}
                            setDateRange={setTmpDateRange}
                        />
                    ) : null
                }
            </DropdownMenu>
        )
    }
);

export default DateRangeDropdown;