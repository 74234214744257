import * as React from "react";

import {CategoryGraphData} from "@/components/ui/chart/pie/pie";
import {echartColors, echartColorsProducer} from "@/styles/echart-styles";
import Echarts, {EchartProps} from "@/components/ui/chart/echarts";

type FunnelProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    series: CategoryGraphData[];
    width?: string;
    height: string;
    colors?: string[];
}

const FunnelChart = React.forwardRef<HTMLDivElement, FunnelProps>(
    (
        {
            className,
            children,
            title,
            series,
            width,
            height,
            colors = echartColorsProducer,
            ...props
        },
        ref
    ) => {
        if (series.length === 0) {
            return null;
        }

        series = series.sort((a, b) => a[1] - b[1]);
        const labels = series.map(serie => serie[0])

        const second_largest = series[1][1];
        const magnitude = Math.pow(10, Math.floor(Math.log10(second_largest)));
        const tickValues = [magnitude, 2 * magnitude, 2.5 * magnitude, 5 * magnitude, 10 * magnitude];

        const roundedValue = tickValues.find(tick => tick >= second_largest);

        const isDarkMode = document.documentElement.classList.contains('dark');

        const funnel_graph: EchartProps["option"] = {
            color: colors,
            animation: false,
            grid: {
                right: "5%",
                left: "15%",
            },
            title: {
                text: title,
                left: "20",
                top: "15",
                textStyle: {
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    fontSize: 14,
                }
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'value',
                max: roundedValue || 10 * magnitude,
                axisLine: {
                    show: false,
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed',
                        color: isDarkMode ? echartColors.greyLine : echartColors.silverLine,
                        width: 1,
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: labels,
                axisTick: {
                    show: false,
                    alignWithLabel: true
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                },
            },
            series: [
                {
                    type: "bar",
                    data: series.map((serie, index) => {
                        return (
                            {
                                name: serie[0],
                                value: serie[1],
                                itemStyle: { color: colors[index] },
                            }
                        );
                    }),
                }
            ]
        }

        return (
            <Echarts
                option={funnel_graph}
                width={width}
                height={height}
            />
        );
    }
);

export {
    FunnelChart,
};