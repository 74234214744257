import {queryOptions, useQuery} from "@tanstack/react-query";

import {ProductDetailLinkResponse} from "@/types";
import {api} from "@/lib/api-client";
import {queryConfig, QueryConfig} from "@/lib/react-query";

export const getComponentLink = ({
    brand,
    component_item
}: {
    brand: string;
    component_item: string;
}): Promise<ProductDetailLinkResponse> => {
    return api.get(`/products/${component_item}/link`, {
        params: {
            brand,
        },
    });
}

export const getProductLinkQueryOptions = ({
    brand,
    component_item
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["products", "link", brand, component_item],
        queryFn: () => getComponentLink({ brand, component_item }),
    })
}

type UseProductLinkOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getProductLinkQueryOptions>;
}

export const useProductLink = ({
    brand,
    component_item,
    queryConfig,
}: UseProductLinkOptions) => {
    return useQuery({
        ...getProductLinkQueryOptions({ brand, component_item }),
        ...queryConfig
    })
}