import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import {QueryClientProvider} from "@tanstack/react-query";
import {PrimeReactProvider} from "primereact/api";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "@/styles/custom-data-table.css";

import {queryClient} from "@/lib/react-query";
import {MainErrorFallback} from "@/components/errors/main";
import {AuthLoader} from "@/lib/auth";
import {createContext, useContext, useState} from "react";


type AppProviderProps = {
    children: React.ReactNode;
}

type BrandContextType = {
    brand: string;
    setBrand: React.Dispatch<React.SetStateAction<string>>;
}

const BrandContext = createContext<BrandContextType | undefined>(undefined);

export const useBrand = (): BrandContextType => {
    const context = useContext(BrandContext);
    if (!context) {
        throw new Error("useBrand must be used within a BrandProvider")
    }

    return context
}

const BrandProvider = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const [brand, setBrand] = useState(localStorage.getItem("brand") ?? "");

    return (
        <BrandContext.Provider value={{ brand, setBrand }}>
            {children}
        </BrandContext.Provider>
    );
}

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <React.Suspense
            fallback={
                <div>
                    Loading...
                </div>
            }
        >
            <ErrorBoundary FallbackComponent={MainErrorFallback}>
                <QueryClientProvider client={queryClient}>
                    <PrimeReactProvider>
                        <BrandProvider>
                            <AuthLoader
                                renderLoading={() => <div>Render Loading...</div>}
                            >
                                {children}
                            </AuthLoader>
                        </BrandProvider>
                    </PrimeReactProvider>
                </QueryClientProvider>
            </ErrorBoundary>
        </React.Suspense>
    );
}