import {queryOptions, useQuery} from "@tanstack/react-query";

import {SalesChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getSalesChart = ({
    brand,
    period,
    startDate,
    endDate
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<SalesChartResponse[]> => {
    return api.get(`/sales/dynamic-chart`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getSalesChartQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["sales", "chart", brand, period, startDate, endDate],
        queryFn: () => getSalesChart({ brand, period, startDate, endDate }),
    });
};

type UseSalesChartOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getSalesChartQueryOptions>;
}

export const useSalesChart = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig
}: UseSalesChartOptions) => {
    return useQuery({
        ...getSalesChartQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}