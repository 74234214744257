import {queryOptions, useQuery} from "@tanstack/react-query";

import {PurchaseListResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getPurchaseItems = ({
    brand,
    selector,
}: {
    brand: string;
    selector: string;
}): Promise<PurchaseListResponse> => {
    return api.get(`/purchase/items`, {
       params: {
           brand,
           selector,
       },
    });
}

export const getPurchaseItemsQueryOptions = ({
    brand,
    selector,
}: {
    brand: string;
    selector: string;
}) => {
    return queryOptions({
        queryKey: ["purchase", "items", brand, selector],
        queryFn: () => getPurchaseItems({ brand, selector }),
    })
}

type UsePurchaseItemsOptions = {
    brand: string;
    selector: string;
    queryConfig?: QueryConfig<typeof getPurchaseItemsQueryOptions>;
}

export const usePurchaseItems = ({
    brand,
    selector,
    queryConfig
}: UsePurchaseItemsOptions) => {
    return useQuery({
        ...getPurchaseItemsQueryOptions({ brand, selector }),
        ...queryConfig
    })
}