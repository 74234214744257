import {queryClient} from "@/lib/react-query";
import {QueryClient} from "@tanstack/react-query";
import {getProductQueryOptions} from "@/features/products/api/get-component";
import {LoaderFunctionArgs, useParams} from "react-router-dom";
import {getProductChartQueryOptions} from "@/features/products/api/get-component-chart";
import {getProductLinkQueryOptions} from "@/features/products/api/get-component-link";
import {useBrand} from "@/app/main-provider";
import React, {useState} from "react";
import {Range} from "react-date-range";
import {ComponentCard} from "@/features/products/components/component-card";
import {ComponentChart} from "@/features/products/components/component-chart";
import {ComponentItems} from "@/features/products/components/component-items";

export const componentDashboardLoader = (queryClient: QueryClient, brand: string) => async ({ params }: LoaderFunctionArgs) => {
    const productId = params.productId as string;
    const period = "Daily";

    if (!brand || !productId) return null;
    const productQuery = getProductQueryOptions({
        brand: brand,
        component_item: productId || "",
    });

    const productChartQuery = getProductChartQueryOptions({
        brand: brand,
        period: period,
        component_item: productId || "",
    });

    const productLinkQuery = getProductLinkQueryOptions({
        brand: brand,
        component_item: productId || "",
    });

    const promises = [
        queryClient.getQueryData(productQuery.queryKey) ??
            (await queryClient.fetchQuery(productQuery)),
        queryClient.getQueryData(productChartQuery.queryKey) ??
            (await queryClient.fetchQuery(productChartQuery)),
        queryClient.getQueryData(productLinkQuery.queryKey) ??
            (await queryClient.fetchQuery(productLinkQuery)),
    ] as const;

    const [ product, productChart, productLink ] = await Promise.all(promises);

    return {
        product,
        productChart,
        productLink,
    }
}

export const ComponentDashboardRoute = () => {
    const { productId } = useParams();
    const { brand, setBrand } = useBrand();
    const [filter, setFilter] = useState("Daily");
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: new Date(new Date().setDate(new Date().getDate() - 2)),
            endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
            key: "selection",
        }]
    )

    if (!brand) return null;

    return (
        <>
            <ComponentCard
                component_item={productId || ""}
                filter={filter}
                setFilter={setFilter}
                dateRange={dateRange}
                setDateRange={setDateRange}
            />
            <ComponentChart
                component_item={productId || ""}
                filter={filter}
                dateRange={dateRange}
            />
            <ComponentItems
                component_item={productId || ""}
            />
        </>
    );
}