import * as React from "react";

import {UseProductsItems} from "@/features/products/api/get-products-items";
import PaginationTable, {
    ColumnVisMapperProps,
    ColumnVisMeta,
    CustomFilterMeta
} from "@/components/ui/table/pagination-table/pagination-table";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {FilterMatchMode} from "primereact/api";


export const ProductsItems = ({
    filter,
    dateRange,
}: {
    filter: string;
    dateRange: Range[];
}) => {
    const { brand, setBrand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const productsItemsQuery = UseProductsItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (productsItemsQuery.isLoading) {
        return <div>Products Table Loading...</div>;
    }

    if (!productsItemsQuery.data) return null;

    const initialFilters: CustomFilterMeta = {
        component_item: { value: null, matchMode: FilterMatchMode.CONTAINS },
        asin: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
        sales_rank: { value: null, matchMode: FilterMatchMode.CUSTOM },
        bb_price: { value: null, matchMode: FilterMatchMode.CUSTOM },
        units: { value: null, matchMode: FilterMatchMode.CUSTOM },
        sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
        inventory_available: { value: null, matchMode: FilterMatchMode.CUSTOM },
        inventory_inbound: { value: null, matchMode: FilterMatchMode.CUSTOM },
    }

    const visMapper: ColumnVisMapperProps = {
        img: ColumnVisMeta.Image,
        asin: ColumnVisMeta.Merge_toImage,
        marketplace: ColumnVisMeta.Amz_link,
        detail_link: ColumnVisMeta.Detail_link,
        display_name: ColumnVisMeta.Long_text,
        component_item: ColumnVisMeta.Long_text,
    }

    return (
        <div>
            <PaginationTable
                data={productsItemsQuery.data.data}
                columns={productsItemsQuery.data.columns}
                initialFilters={initialFilters}
                visMapper={visMapper}
                dateRange={dateRange}
            />
        </div>
    )
}