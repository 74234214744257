import Axios, { InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { env } from '@/config/env';
import Cookies from 'js-cookie';
import {getToken} from "@/lib/auth";

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
    if (config.headers) {
        config.headers.Accept = 'application/json';
    }

    if (config.method === 'post' && config.url === '/token') {
        config.headers.Accept = 'application/x-www-form-urlencoded';
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    config.headers.Authorization = `Bearer ${getToken()}`;

    const axiosId = uuidv4();
    console.debug("outbound request", {
        baseUrl: config.baseURL,
        method: config.method,
        data: config.data,
        params: config.params,
        url: config.url,
        headers: config.headers,
        axiosId,
    });

    config.withCredentials = true;
    return config;
}

export const api = Axios.create({
    baseURL: env.API_URL,
});

api.interceptors.request.use(authRequestInterceptor);
api.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (error.response) {
            const message = error.response?.data?.message || error.message;
            console.log(message);

            // Handle 401 Unauthorized - redirect to login page
            if (error.response?.status === 401 || error.response?.status === 403) {
                // const searchParams = new URLSearchParams();
                // const redirectTo = searchParams.get('redirectTo')
                // window.location.href = `${redirectTo? `/auth/login?redirectTo=${redirectTo}`: '/'}`; // Redirect to login page

                Cookies.remove('token');
                sessionStorage.removeItem('token');
                window.location.href = '/auth/login'; // Redirect to login page
            } else if (error.response?.status === 500) {
                throw new Error(`${error.response?.status} Server Error`);
            }
        } else {
            console.log('Network Error', error.message);
            alert('Network Error. Please try again later.');
        }

        return Promise.reject(error);
    }
);
