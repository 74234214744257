import {useProduct} from "@/features/products/api/get-component";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import SimpleCard from "@/components/ui/card/simple-card/simple-card";

export const ComponentCard = ({
    component_item,
    filter,
    setFilter,
    dateRange,
    setDateRange,
}: {
    component_item: string;
    filter: string;
    setFilter: (filter: string) => void;
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
}) => {
    const { brand, setBrand } = useBrand();

    const componentCardQuery = useProduct({
        brand: brand,
        component_item: component_item
    });

    if (componentCardQuery.isLoading) {
        return <div>Component Card Loading...</div>;
    }

    if (!componentCardQuery.data) return null;

    return (
        <SimpleCard
            data={componentCardQuery.data}
            filter={filter}
            setFilter={setFilter}
            dateRange={dateRange}
            setDateRange={setDateRange}
        >
            {component_item}
        </SimpleCard>
    );
}