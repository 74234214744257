import {useProductLink} from "@/features/products/api/get-component-link";
import {useBrand} from "@/app/main-provider";
import PaginationTable from "@/components/ui/table/pagination-table/pagination-table";

export const ComponentItems = ({
    component_item,
}: {
    component_item: string;
}) => {
    const { brand, setBrand } = useBrand();

    const componentItemsQuery = useProductLink({
        brand: brand,
        component_item: component_item,
    });

    if (componentItemsQuery.isLoading) {
        return <div>Component Table Loading...</div>;
    }

    if (!componentItemsQuery.data) return null;

    return (
        <PaginationTable
            data={componentItemsQuery.data.data}
            columns={componentItemsQuery.data.columns}
            initialFilters={{}}
            visMapper={{}}
        />
    );
}