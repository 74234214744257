import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {useAdvertisingChart} from "@/features/advertising/api/get-advertising-chart";
import {MultipleAxisTimeValueLineChart} from "@/components/ui/chart/line/multiple-axis-line";
import {echartColors} from "@/styles/echart-styles";

export const AdvertisingChart = ({
    filter,
    dateRange,
    height = "21.4rem",
}: {
    filter: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand, setBrand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingChartQuery = useAdvertisingChart({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (advertisingChartQuery.isLoading) {
        return <div>Advertising Chart Loading...</div>;
    }

    if (!advertisingChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueLineChart
            title={"Advertising Chart"}
            labels={[
                { name: "Sales", position: "right" },
                { name: "ROAS", position: "left", offset: 30 },
                { name: "Spend", position: "left" },
            ]}
            series={[
                advertisingChartQuery.data.map(row => [row.date, row.total_ad_sales]),
                advertisingChartQuery.data.map(row => [row.date, row.roas]),
                advertisingChartQuery.data.map(row => [row.date, row.total_ad_spend]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
                echartColors.white,
            ]}
        />
    );
}