import {QueryClient} from "@tanstack/react-query";
import {getProductsQueryOptions} from "@/features/products/api/get-products";
import {getProductsItemsQueryOptions} from "@/features/products/api/get-products-items";
import {ProductCard} from "@/features/products/components/products-card";
import {ProductsItems} from "@/features/products/components/products-items";
import React, {useState} from "react";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";

export const productsDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;

    const productsItemsQuery = getProductsItemsQueryOptions({
        brand: brand,
    });

    const promises = [
        queryClient.getQueryData(productsItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(productsItemsQuery)),
    ] as const;

    const [ productsItems ] = await Promise.all(promises);

    return {
        productsItems,
    }
}

export const ProductsDashboardRoute = () => {
    const { brand, setBrand } = useBrand();
    const [filter, setFilter] = useState("Daily");
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
            endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            <div className={"flex justify-between"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Products</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <ProductsItems
                filter={filter}
                dateRange={dateRange}
            />
        </>
    );
}