import * as React from "react";
import {cn} from "@/utils/cn";
import Button from "@/components/ui/button/button";
import {ButtonProps} from "@/components/ui/button/button";

const DropdownMenu  = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <div ref={ref} className={cn("", className)} {...props} />
    )
);
DropdownMenu.displayName = "Dropdown"

const DropdownTrigger = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <Button ref={ref} className={cn("hs-dropdown-toggle", className)} variant="dropdown" size="dropdown" aria-haspopup="menu" aria-label="Dropdown" {...props} />
    )
);
DropdownTrigger.displayName = "DropdownTrigger";

const DropdownIconDown = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
             className="lucide lucide-chevron-down">
            <path d="m6 9 6 6 6-6"/>
        </svg>
    )
);
DropdownIconDown.displayName = "DropdownIconDown";

const DropdownIconUp = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
             className="lucide lucide-chevron-up">
            <path d="m18 15-6-6-6 6"/>
        </svg>
    )
);
DropdownIconUp.displayName = "DropdownIconUp";

const DropdownMenuContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <div ref={ref}
             className={cn("absolute hs-dropdown-menu transition-[opacity,margin] duration-150 w-56 z-10 mt-2 min-w-28 bg-white shadow-md rounded-lg p-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700", className)}
             role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-example" {...props} />
    )
);
DropdownMenuContent.displayName = "DropdownMenuContent";

type DropdownMenuItemProps = React.HTMLAttributes<HTMLButtonElement> &{
    value?: string;
}

const DropdownMenuItem = React.forwardRef<HTMLButtonElement, DropdownMenuItemProps>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <Button
            ref={ref}
            className={cn("flex items-center w-full gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 pointer-events-auto", className)}
            onClick={props.onClick}
            {...props}
        />
    )
);
DropdownMenuItem.displayName = "DropdownMenuItem";

const DropdownMenuItemCheck = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
             className="lucide lucide-check">
            <path d="M20 6 9 17l-5-5"/>
        </svg>
    )
);

export {DropdownMenu, DropdownTrigger, DropdownIconDown, DropdownIconUp, DropdownMenuContent, DropdownMenuItem, DropdownMenuItemCheck};