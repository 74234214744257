import {QueryClient} from "@tanstack/react-query";
import {SalesChart} from "@/features/sales/components/sales-chart";
import React, {useState} from "react";
import {getSalesChartQueryOptions} from "@/features/sales/api/get-sales-chart";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {SalesAnnualChart} from "@/features/sales/components/sales-annual-chart";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {SalesItems} from "@/features/sales/components/sales-items";
import {getSalesAnnualChartQueryOptions} from "@/features/sales/api/get-sales-annual-chart";
import {getSalesItemsQueryOptions} from "@/features/sales/api/get-sales-items";

export const salesDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;

    const salesAnnualChartQuery = getSalesAnnualChartQueryOptions({
        brand: brand,
    })

    const salesChartQuery = getSalesChartQueryOptions({
        brand: brand,
    });

    const salesItemsQuery = getSalesItemsQueryOptions({
        brand: brand,
    })

    const promises = [
        queryClient.getQueryData(salesAnnualChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesAnnualChartQuery)),
        queryClient.getQueryData(salesChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesChartQuery)),
        queryClient.getQueryData(salesItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(salesItemsQuery)),
    ] as const;
    const [salesAnnualChart, salesChart, salesItems] = await Promise.all(promises);

    return {
        salesAnnualChart,
        salesChart,
        salesItems
    }
}

export const SalesDashboardRoute = () => {
    const { brand, setBrand } = useBrand();
    const [filter, setFilter] = React.useState("Daily");
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
            endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            {/*<SalesCard*/}
            {/*    filter={filter}*/}
            {/*    setFilter={setFilter}*/}
            {/*    dateRange={dateRange}*/}
            {/*    setDateRange={setDateRange}*/}
            {/*/>*/}
            <div className={"flex justify-between"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Sales</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <div className={"grid grid-cols-2 gap-x-5"}>
                <SalesAnnualChart
                    filter={filter}
                />
                <SalesChart
                    filter={filter}
                    dateRange={dateRange}
                />
            </div>
            <SalesItems
                filter={filter}
                dateRange={dateRange}
            />
        </>
    );
}