import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdOrganicPieResponse} from "@/types";

export const getAdvertisingOrganicPie = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdOrganicPieResponse[]> => {
    return api.get(`/advertising/organic-pie`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getAdvertisingPieQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "organic-pie", brand, period, startDate, endDate],
        queryFn: () => getAdvertisingOrganicPie({ brand, period, startDate, endDate }),
    })
}

type UseAdvertisingPieOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAdvertisingPieQueryOptions>;
}

export const useAdvertisingPie = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAdvertisingPieOptions) => {
    return useQuery({
        ...getAdvertisingPieQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}