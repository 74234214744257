import * as React from "react";
import {cn} from "@/utils/cn";
import {HTMLAttributes} from "react";
import {CustomFilterMeta} from "@/components/ui/table/pagination-table/pagination-table";
import {ChevronDown, ChevronsUpDown, ChevronUp} from "lucide-react";
import {FilterMatchMode} from "primereact/api";

type FilterBoxProps = HTMLAttributes<HTMLDivElement> & {
    filters: CustomFilterMeta,
    setFilters: (filters: (prevFilter: CustomFilterMeta) => CustomFilterMeta) => void,
    filterKey?: string,
    filter?: { value: any, matchMode: FilterMatchMode },
    columnName?: string,
}

const DataTableNumberFilterBox = React.forwardRef<HTMLDivElement, FilterBoxProps>(
    (
        {
            className,
            filters,
            setFilters,
            filterKey,
            filter,
            columnName,
            ...props
        },
        ref
    ) => (
        <div className="relative">
            <input
                type="text"
                className={"block p-2 h-10 w-full text-sm text-gray-900 dark:text-white bg-white dark:bg-rigleDark border border-[#e6e6e6] dark:border-rigleGreyLine rounded-md focus:outline-none focus:ring-2 focus:ring-rigleYellow focus:border-transparent"}
                value={filter?.value ?? ''}
                onChange={(e) => {
                    const newValue = e.target.value.trim() === "" ? null : e.target.value;
                    if (!filterKey) return null;
                    setFilters(prevFilter => ({
                        ...prevFilter,
                        [filterKey]: {...prevFilter[filterKey as keyof CustomFilterMeta], value: newValue}
                    }))
                }}
            />
            <label
                htmlFor={filterKey}
                className={"absolute text-xs -translate-y-4 top-2 z-10 origin-[0] bg-white dark:bg-rigleDark dark:text-white px-2 left-4"}
            >
                {columnName}
            </label>
        </div>
    )
);
DataTableNumberFilterBox.displayName = "DataTableNumberFilterBox";

const DataTableRangeFilterBox = React.forwardRef<HTMLDivElement, FilterBoxProps>(
    (
        {
            className,
            filters,
            setFilters,
            filterKey,
            filter,
            columnName,
            ...props
        },
        ref
    ) => {
        return (
            <div className="relative">
                <div
                    className={"p-2 h-10 text-sm text-gray-900 dark:text-white bg-white dark:bg-rigleDark flex items-center border border-[#e6e6e6] dark:border-rigleGreyLine rounded-md focus:outline-none focus-within:ring-2 focus-within:ring-rigleYellow"}>
                    <input
                        type="text"
                        placeholder={"From"}
                        className={"focus:outline-none w-full max-w-[45%] dark:bg-rigleDark"}
                        value={filter?.value?.[0] ?? ''}
                        onChange={(e) => {
                            const newFromValue = e.target.value.trim() === "" ? null : e.target.value;
                            if (!filterKey) return null;
                            setFilters(prevFilter => ({
                                ...prevFilter,
                                [filterKey]: {
                                    ...prevFilter[filterKey as keyof CustomFilterMeta],
                                    value: [newFromValue, prevFilter[filterKey as keyof CustomFilterMeta].value?.[1] ?? null]
                                }
                            }))
                        }}
                    />
                    <span className={"mx-2 text-[#696969] dark:text-rigleDarkGrey"}>|</span>
                    <input
                        type="text"
                        placeholder={"To"}
                        className={"focus:outline-none w-full max-w-[45%] dark:bg-rigleDark"}
                        value={filter?.value?.[1] ?? ''}
                        onChange={(e) => {
                            const newToValue = e.target.value.trim() === "" ? null : e.target.value;
                            if (!filterKey) return null;
                            setFilters(prevFilter => ({
                                ...prevFilter,
                                [filterKey]: {
                                    ...prevFilter[filterKey as keyof CustomFilterMeta],
                                    value: [prevFilter[filterKey as keyof CustomFilterMeta].value?.[0] ?? null, newToValue],
                                }
                            }))
                        }}
                    />
                </div>
                <label
                    htmlFor={filterKey}
                    className={"absolute text-xs -translate-y-4 top-2 z-10 origin-[0] bg-white dark:bg-rigleDark dark:text-white px-2 left-4"}
                >
                    {columnName}
                </label>
            </div>
        );
    }
);
DataTableRangeFilterBox.displayName = "DataTableRangeFilterBox";

const DataTableSearchBar = React.forwardRef<HTMLDivElement, FilterBoxProps>(
    (
        {
            className,
            filters,
            setFilters,
            ...props
        },
        ref
    ) => (
        <div
            className="w-[20rem] px-3 py-2 bg-white dark:bg-[#44454D] rounded-[10px] shadow border border-[#ccd3d9] dark:border-rigleDarkGrey justify-start items-center gap-2 inline-flex mr-auto">
            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                <div className="h-5 text-[#8B8B8B]">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z"
                            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path d="M17.5001 18.0001L13.9167 14.4167" stroke="currentColor" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <input
                    type="text"
                    className={"grow shrink basis-0 text-[#899399] dark:text-white dark:bg-[#44454D] text-sm font-normal font-['ABC Diatype'] leading-normal focus:outline-none dark:placeholder-[#8A9499] dark:placeholder:leading-6"}
                    value={filters.global.value ?? ''}
                    onChange={(e) => {
                        const newValue = e.target.value.trim() === "" ? null : e.target.value;
                        setFilters(prevFilter => ({
                            ...prevFilter,
                            global: {...prevFilter.global, value: newValue}
                        }))
                    }}
                    placeholder="Keyword Search"
                />
            </div>
        </div>
    )
);
DataTableSearchBar.displayName = "DataTableSearchBar";

const DataTableSortIcon = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <ChevronsUpDown strokeWidth={3} size={18}/>
    )
);
DataTableSortIcon.displayName = "DataTableSortIcon";

const DataTableSortAscendingIcon = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <ChevronUp strokeWidth={3} size={18}/>
    )
);
DataTableSortAscendingIcon.displayName = "DataTableSortAscendingIcon";

const DataTableSortDescendingIcon = React.forwardRef<HTMLOrSVGElement, React.HTMLAttributes<HTMLOrSVGElement>>(
    (
        {
            className,
            ...props
        },
        ref
    ) => (
        <ChevronDown strokeWidth={3} size={18}/>
    )
);
DataTableSortDescendingIcon.displayName = "DataTableSortDescendingIcon";

export {
    DataTableNumberFilterBox,
    DataTableRangeFilterBox,
    DataTableSearchBar,
    DataTableSortIcon,
    DataTableSortAscendingIcon,
    DataTableSortDescendingIcon
};