import * as React from "react";

import {Range} from "react-date-range";

import {useBrand} from "@/app/main-provider";
import {OneAxisTimeValueLineChart} from "@/components/ui/chart/line/one-axis-line";
import {useSalesChart} from "@/features/sales/api/get-sales-chart";
import {echartColors} from "@/styles/echart-styles";

export const SalesChart = ({
    filter,
    dateRange,
    height = "18.5rem",
}: {
    filter: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand, setBrand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesChartQuery = useSalesChart({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesChartQuery.isLoading) {
        return <div>Sales Chart Loading...</div>;
    }

    if (!salesChartQuery.data) return null;

    return (
        <OneAxisTimeValueLineChart
            title={"Sales"}
            labels={["Sales"]}
            series={[
                salesChartQuery.data.map(row => [row.date, row.sales])
            ]}
            height={height}
            colors={[
                echartColors.green
            ]}
        />
    );
}