import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    DropdownIconDown,
    DropdownIconUp,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem, DropdownMenuItemCheck,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {cn} from "@/utils/cn";

const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type SimpleDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
    filter: string;
    filterOptions: string[];
    setFilter: (filter: string) => void;
    localStorageKey?: string;
}

const SimpleDropdown = React.forwardRef<HTMLDivElement, SimpleDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            filter,
            filterOptions,
            setFilter,
            localStorageKey,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);
        const isScrollable = filterOptions.length > 17;

        const handleSelect = (event: any) => {
            if (localStorageKey && event.target.value !== undefined) {
                localStorage.setItem(localStorageKey, event.target.value);
            }

            setFilter(event.target.value);
        }

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef} className={cn({ variant, size, className })}>
                <DropdownTrigger aria-expanded={isOpen} onClick={toggleDropdown}>
                    <span className={cn("", className)}>{filter}</span>
                    {
                        isOpen ? <DropdownIconUp /> : <DropdownIconDown />
                    }
                </DropdownTrigger>

                {
                    isOpen ? (
                        <DropdownMenuContent className={cn(`max-h-[40rem] ${isScrollable ? "overflow-y-scroll": ""}`, className)}>
                            {filterOptions?.map((value, index) => (
                                <div className={"flex items-center dark:text-white"}>
                                    {
                                        value === filter ? <DropdownMenuItemCheck /> : null
                                    }
                                    <DropdownMenuItem className={value === filter ? "font-bold" : ""} key={value + index} value={value} onClick={handleSelect}>
                                        {value}
                                    </DropdownMenuItem>
                                </div>
                            ))}
                        </DropdownMenuContent>
                    ) : null
                }

            </DropdownMenu>
        );
    }
);

export default SimpleDropdown;