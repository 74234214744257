import * as React from "react";
import {cn} from "@/utils/cn";

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref,
    ) => (
        <div className={cn("bg-white rounded-xl w-full py-2.5 px-5 flex flex-col gap-y-4 h-[10rem]", className)} {...props} >
        </div>
    )
);

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            children,
            ...props
        },
        ref
    ) => (
        <div className={cn("font-semibold text-gray-500 text-base", className)} {...props} >
            {children}
        </div>
    )
);

const CardBody = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            children,
            ...props
        },
        ref
    ) => (
        <div className={cn("font-bold text-2xl", className)} {...props} >
            {children}
        </div>
    )
);

export { Card, CardHeader, CardBody };