import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdChartResponse} from "@/types";

export const getAdvertisingChart = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdChartResponse[]> => {
    return api.get(`/advertising/chart`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getAdvertisingChartQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "chart", brand, period, startDate, endDate],
        queryFn: () => getAdvertisingChart({ brand, period, startDate, endDate }),
    })
}

type UseAdvertisingChartOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAdvertisingChartQueryOptions>;
}

export const useAdvertisingChart = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAdvertisingChartOptions) => {
    return useQuery({
        ...getAdvertisingChartQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}