import {queryOptions, useQuery} from "@tanstack/react-query";

import {CardResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponent = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}): Promise<CardResponse> => {
    return api.get(`/products/${component_item}`, {
        params: {
            brand,
        },
    });
}

export const getProductQueryOptions = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["product", brand, component_item],
        queryFn: () => getComponent({ brand, component_item }),
    })
}

type UseProductOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getProductQueryOptions>;
}

export const useProduct = ({
    brand,
    component_item,
    queryConfig,
}: UseProductOptions) => {
    return useQuery({
        ...getProductQueryOptions({ brand, component_item }),
        ...queryConfig
    })
}