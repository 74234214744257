import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";
import {Spinner} from "@/components/ui/spinner/spinner";

const buttonVariants = cva(
    "flex items-center justify-center",
    {
        variants: {
            variant: {
                default: "",
                dropdown: "inline-flex text-gray-500 rounded-lg border border-gray-200 bg-white shadow-sm font-semibold hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700",
                icon: "inline-flex justify-center items-center rounded-full border border-gray-200 bg-white text-gray-700 shadow-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400",
                rigle: "bg-gradient-to-r from-rigleYellow to-rigleGreen text-black text-lg font-bold font-['ABC Diatype'] leading-snug rounded-xl",
                tableExport: "flex px-4 bg-[#E3E811] rounded-lg",
                detailLink: "bg-[#38FD33] text-black hover:bg-[#1BDB16]",
                amazonLink: "bg-[#231F20] text-white hover:bg-black",
            },
            size: {
                default: '',
                dropdown: "py-1.5 px-2 gap-x-1 text-sm",
                icon: "size-[46px]",
                tableLink: "px-4 py-3 rounded-[10px] ",
                full: "w-full py-3",
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    isLoading?: boolean;
    icon?: React.ReactNode;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            children,
            isLoading,
            icon,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button";
        return (
            <Comp
                ref={ref}
                className={cn(buttonVariants({variant, size, className}))}
                {...props}
            >
                {isLoading && <Spinner size={"sm"} className={"text-current"} />}
                {!isLoading && icon && <span className={"mr-2 flex-shrink-0"}>{icon}</span>}
                <span className={"mx-2 flex items-center pointer-events-none"}>{children}</span>
            </Comp>
        );
    },
);

export default Button;