import {Outlet, useLocation, useSearchParams} from "react-router-dom";
import {DashboardLayout} from "@/components/layouts/dashboard-layout";
import {Suspense, useEffect} from "react";
import * as React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {useBrand} from "@/app/main-provider";
import {AuthLayout} from "@/components/layouts/auth-layout";

export const AppRoot = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { brand, setBrand } = useBrand();


    useEffect(() => {
        const brandParam = searchParams.get("brand");
        if (brandParam) {
            setBrand(brandParam);
        }
    }, [searchParams]);

    return (
        <DashboardLayout>
            <Suspense
                fallback={
                    <div>
                        Loading...
                    </div>
                }
            >
                <ErrorBoundary
                    key={location.pathname}
                    fallback={<div>Something went wrong!</div>}
                >
                    <Outlet />
                </ErrorBoundary>
            </Suspense>
        </DashboardLayout>
    );
}

export const AuthRoot = () => {
    const location = useLocation();

    return (
        <AuthLayout>
            <Suspense
                fallback={
                    <div>
                        Loading...
                    </div>
                }
            >
                <ErrorBoundary
                    key={location.pathname}
                    fallback={<div>Something went wrong!</div>}
                >
                    <Outlet />
                </ErrorBoundary>
            </Suspense>
        </AuthLayout>
    );
}