import {queryOptions, useQuery} from "@tanstack/react-query";

import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";
import {AdListResponse} from "@/types";

export const getAdvertisingItems = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}): Promise<AdListResponse> => {
    return api.get(`/advertising/items`, {
        params: {
            brand,
            period,
            startDate,
            endDate,
        },
    });
}

export const getAdvertisingItemsQueryOptions = ({
    brand,
    period,
    startDate,
    endDate,
}: {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
}) => {
    return queryOptions({
        queryKey: ["advertising", "items", brand, period, startDate, endDate],
        queryFn: () => getAdvertisingItems({ brand, period, startDate, endDate }),
    })
}

type UseAdvertisingItemsOptions = {
    brand: string;
    period?: string;
    startDate?: string;
    endDate?: string;
    queryConfig?: QueryConfig<typeof getAdvertisingItemsQueryOptions>;
}

export const useAdvertisingItems = ({
    brand,
    period,
    startDate,
    endDate,
    queryConfig,
}: UseAdvertisingItemsOptions) => {
    return useQuery({
        ...getAdvertisingItemsQueryOptions({ brand, period, startDate, endDate }),
        ...queryConfig,
    })
}