import * as React from "react";
import {usePurchaseItems} from "@/features/purchase/api/get-purchase-items";
import PaginationTable, {
    ColumnVisMapperProps,
    ColumnVisMeta
} from "@/components/ui/table/pagination-table/pagination-table";
import {useBrand} from "@/app/main-provider";


export const PurchaseItems = ({
    filter,
}: {
    filter: string;
}) => {
    const { brand, setBrand } = useBrand();

    const purchaseItemsQuery = usePurchaseItems({
        brand: brand,
        selector: filter
    });

    if (purchaseItemsQuery.isLoading) {
        return <div>Purchase Table Loading...</div>;
    }

    if (!purchaseItemsQuery.data) return null;

    const visMapper: ColumnVisMapperProps = {
        component_display_name: ColumnVisMeta.Long_text,
    }

    return (
        <div>
            <PaginationTable
                data={purchaseItemsQuery.data.data}
                columns={purchaseItemsQuery.data.columns}
                initialFilters={{}}
                visMapper={visMapper}
            />
        </div>
    );
}