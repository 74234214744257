import * as React from 'react';

import {useBrand} from "@/app/main-provider";
import {useSalesAnnualChart} from "@/features/sales/api/get-sales-annual-chart";
import {OneAxisTimeValueLineChart} from "@/components/ui/chart/line/one-axis-line";
import {echartColors} from "@/styles/echart-styles";

export const SalesAnnualChart = ({
    filter,
    height = "18.5rem",
}: {
    filter: string;
    height?: string;
}) => {
    const { brand, setBrand } = useBrand();

    const salesAnnualChartQuery = useSalesAnnualChart({
        brand: brand,
    })

    if (salesAnnualChartQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (!salesAnnualChartQuery.data) return null;

    return (
        <OneAxisTimeValueLineChart
            title={"Annual Sales by Monthly"}
            labels={["Sales"]}
            series={[
                salesAnnualChartQuery.data.map(row => [row.date, row.sales])
            ]}
            height={height}
            colors={[
                echartColors.purple
            ]}
        />
    );
}