import Button from "@/components/ui/button/button";

export const TableNoData = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-gray-900 text-center" role="alert">
            <h2 className="text-3xl font-semibold mb-4">Ops, there is no data... :(</h2>
            <Button
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                onClick={() => window.location.assign(window.location.origin)}
            >
                Refresh
            </Button>
        </div>
    );
}