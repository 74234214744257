import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import ReactECharts from "echarts-for-react";

import { EChartsOption } from "echarts";
import {cn} from "@/utils/cn";
import {CallbackDataParams} from "echarts/types/dist/shared";

const chartVariants = cva(
    "bg-white rounded-xl dark:bg-rigleDark",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type EchartProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof chartVariants> & {
    option: EChartsOption;
    width?: string;
    height: string;
}

const Echarts = React.forwardRef<HTMLDivElement, EchartProps>(
    (
        {
            className,
            variant,
            size,
            children,
            option,
            width = "auto",
            height,
            ...props
        },
        ref
    ) => {
        return (
            <div className={cn(`w-${width} h-${height}`, chartVariants({ variant, size, className }))}>
                <ReactECharts option={option} style={{ width: width, height: height }} />
            </div>
        );
    },
);

export const timeSeriesTooltipFormatter = (params: CallbackDataParams | CallbackDataParams[]) => {
    let tooltipContent = "";

    const date = new Date(((Array.isArray(params) ? params[0].value: params.value) as [number, number])[0]);
    const date_str = `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`;
    tooltipContent += `
        <div style="margin-bottom: 5px; font-weight: bold;">${date_str}</div>
    `;

    const paramList = !Array.isArray(params) ? [params] : params;
    paramList.forEach((param: CallbackDataParams) => {
        const value = (param.value as [number, number])[1];

        tooltipContent += `
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span style="display: flex; align-items: center;">
                    ${param.marker} ${param.seriesName}
                </span>
                <span style="font-weight: bold; margin-left: 10px;">
                    ${value.toLocaleString()}
                </span>
            </div>
        `;
    })

    return tooltipContent;
}

export default Echarts;